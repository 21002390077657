import { Form, FormField, FormItem, FormLabel } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { TypographyH2 } from "@/components/ui/typography/h2"
import { UseFormReturn } from "react-hook-form"
import { DevLoginType } from "./DevAuthorizerContext"
import { Button } from "@/components/ui/button"
import spotSurferIcon from "@/assets/pngs/spotSurfer.png"

export interface DevLoginProps {
  apiError: string
  form: UseFormReturn<DevLoginType>
  onSubmit: (values: DevLoginType) => void
}

export const DevLogin = ({ apiError, form, onSubmit }: DevLoginProps) => {
  return (
    <div className="flex justify-center h-screen bg-primary">
      <div className="flex flex-col justify-center">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit, console.error)}
            className="shadow-2xl rounded bg-white p-8 grid gap-6">
            <img className="w-20 mx-auto" src={spotSurferIcon} />
            <TypographyH2 className="text-lg">
              Access to the development environment requires login.
            </TypographyH2>
            <FormField
              control={form.control}
              name="username"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <Input
                    className="text-black"
                    type="email"
                    placeholder="username@spotsurfer.com"
                    {...field}
                  />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <Input className="text-black" type="password" {...field} />
                </FormItem>
              )}
            />
            <div className="my-5 grid grid-cols-[1fr_auto] items-center">
              <span className="text-accent-red">{apiError ?? ""}</span>
              <Button
                type="submit"
                className="grid-start-2 rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 cursor-pointer bg-accent-orange text-foreground hover:bg-primary hover:text-white disabled:hover:bg-accent-orange">
                Submit
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </div>
  )
}
